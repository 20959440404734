const getExtensionistsCommissionPay=async(Ffin)=>{
    // const host =`https://api-intranet-credicor.herokuapp.com/`
    // const host = `http://localhost:3400/`;
    const host = process.env.REACT_APP_URL_API_INTRANET;
    let FConsulta = Ffin;
    if (FConsulta === ""){
        FConsulta=0;
    }
    const url=`${host}api/comisiones/comisionescalculoext/${ FConsulta }`;

    const token=localStorage.getItem('token')

    const resp = await fetch(url, {
        method: "GET", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
            "Authorization": token
        }
    });
        const { result, Success, Message, Error } = await resp.json();
        let idx = 0;
        let comisionCalcExt = [];
        if(result !== undefined){
            comisionCalcExt = result.map(elemen => {
                    return {
                        Success,
                        idx: idx+=1,
                        idpay: elemen.ID,
                        idPersonal: elemen.IDPersonal,
                        porcP: elemen.porcP,
                        porcC: elemen.porcC,
                        comCR: elemen.comCR,
                        comAP: elemen.comAP,
                        comVP: elemen.comVP,
                        comIN: elemen.comIN,
                        bonos: elemen.bonos,
                        totalPago: elemen.totalPago,
                        status: elemen.status,
                        personal: elemen.personal,
                        puesto: elemen.puesto,
                        lTitulo: elemen.lTitulo,
                        lAutorizarAP: elemen.lAutorizarAP,
                        lAutorizarCR: elemen.lAutorizarCR,
                        lAutorizarIN: elemen.lAutorizarIN,
                        lAutorizarVP: elemen.lAutorizarVP,
                        Message
                    }
                });
        } else {
            comisionCalcExt = [{Success, idx:idx+=1 , Error}];
        }
        return comisionCalcExt;
}

export default getExtensionistsCommissionPay;

import React from "react";
import { Link } from 'react-router-dom'
import { financial } from '../functions/Generales'

const TablesRecordsItemsInvNvas = (props) => {
const { idx, Success, importe, plazo, cliente, empresa ,porcentajecomision, comision, idmov, producto} = props.data;
  if ( Success ){
    return (
      <tbody>
        <tr>
          <td>{idx}</td>
          <td align='right'><Link to={`/commissions-inversiones-nuevas-details/${ idmov }/${ producto }`}>{cliente}</Link></td>
          <td align='right'>{financial(importe)}</td>
          <td align='right'>{plazo}</td>
          <td align='left'>{empresa}</td>
          <td align='right'>{(porcentajecomision * 100).toFixed(4)}</td>
          <td align='right'>{financial(comision)}</td>
        </tr>
      </tbody>
    );
  }else{
    return (
      <tbody>
        <tr>
          <td>{idx}</td>
          <td align='left'>No existe información</td>
        </tr>
      </tbody>
    );
  }

};

export default TablesRecordsItemsInvNvas;

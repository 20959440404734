import React, { useContext } from "react";
// import { Link } from 'react-router-dom'
import { financial, formatNum } from '../functions/Generales'
import { UserContext } from "../helpers/auth";

const TablesRecordsItemsExtensionistsCommissionsPay = (props) => {
  const {userAuth}=useContext(UserContext);

  const IDDepartamentoUserLogin = userAuth.idDepartamento;
  const lAprobarMovs = IDDepartamentoUserLogin === 6 ||IDDepartamentoUserLogin === 11 ? false : true

  const { idx, Success, idpay, porcP, porcC, comCR, comAP,comIN, comVP, puesto, idPersonal, bonos, totalPago, status, lTitulo, lAutorizarAP, lAutorizarCR, lAutorizarIN, lAutorizarVP} = props.data;
  if ( Success ){
    return (
      <tbody>
        <tr>
          <td>{idPersonal==='' ? '' : idx }</td>
          {lTitulo==='S' ? <td align='left'><b>{ puesto }</b></td> : <td align='left'>{ puesto }</td>}
          <td align='right'>{idPersonal==='' ? '' : formatNum(porcP)}</td>
          <td align='right'>{idPersonal==='' ? '' : formatNum(porcC)}</td>
          {lTitulo==='S' ? <td align='right'><b>{financial(comAP)}</b></td> : <td align='right'>{financial(comAP)}{lAutorizarAP==="N" ? '*' : ''}</td>}
          {lTitulo==='S' ? <td align='right'><b>{financial(comCR)}</b></td> : <td align='right'>{financial(comCR)}{lAutorizarCR==="N" ? '*' : ''}</td>}
          {lTitulo==='S' ? <td align='right'><b>{financial(comVP)}</b></td> : <td align='right'>{financial(comVP)}{lAutorizarVP==="N" ? '*' : ''}</td>}
          <td align='right'>{idpay > 0 ? financial(comIN) : ''}{lAutorizarIN==="N" ? '*' : ''}</td>
          <td align='right'>{idPersonal==='' ? '' : financial(bonos)}</td>
          <td align='right'>{idPersonal==='' ? '' : financial(totalPago)}</td>
          <td>
          <div>
            {
              lAprobarMovs ? '' : props.loadingAuthorization.lLoadingData && props.loadingAuthorization.IDMov === idpay ? 
              <button className="button is-info is-loading" ></button> :
              (props.loadingAuthorization.lLoadingData || props.loadingDisavowal.lLoadingData) && status === 1 ? <button className="button is-info fas fa-check"></button> :
              status === 1 ? <button className="button is-info fas fa-check" onClick={ (e) => { props.handleAuthorizationMov(idpay); } } ></button> 
              : idpay > 0 && status === 3 ? <button className="button is-info fas fa-clipboard-check"></button> : ""
            }
            {
              lAprobarMovs ? '' : props.loadingDisavowal.lLoadingData && props.loadingDisavowal.IDMov === idpay ? 
              <button className="button is-info is-loading" ></button> :
              (props.loadingAuthorization.lLoadingData || props.loadingDisavowal.lLoadingData) && status === 2 ? <button className="button is-info fas fa-trash-alt"></button> :
              status === 2 && idpay > 0 ? <button className="button is-info fas fa-trash-alt" onClick={ (e) => props.handleDisawovalMov(idpay) }></button> 
              : ""
            }
          </div>
        </td>
        </tr>
      </tbody>
    );
  }else{
    return (
      <tbody>
        <tr>
          <td>{idx}</td>
          <td align='left'>No existe información</td>
        </tr>
      </tbody>
    );
  }

};

export default TablesRecordsItemsExtensionistsCommissionsPay;
